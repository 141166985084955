<template>
  <ion-app>
    <ion-menu content-id="main-content" type="reveal">
      <ion-content>
        <ion-list mode="ios">
          <ion-menu-toggle auto-hide="false">
            <ion-item v-if="user" href="/organization/all">
              <ion-icon
                slot="start"
                :ios="bookmarkOutline"
                :md="bookmarkOutline"
              ></ion-icon>
              <ion-label>Your Organizations</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false">
            <ion-item v-if="user" href="/create-new-organization">
              <ion-icon
                slot="start"
                :ios="addCircleOutline"
                :md="addCircleOutline"
              ></ion-icon>
              <ion-label>Create Organization</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false" v-if="user">
            <ion-item
              router-direction="root"
              router-link="/change-password"
              lines="none"
              detail="false"
              class="hydrated"
            >
              <ion-icon
                slot="start"
                :ios="refreshOutline"
                :md="refreshOutline"
              ></ion-icon>
              <ion-label class="large-font">Change Password</ion-label>
            </ion-item>
            <ion-item
              @click="logout()"
              router-direction="root"
              router-link="/login"
              lines="none"
              detail="false"
              class="hydrated"
            >
              <ion-icon
                slot="start"
                :ios="logOutOutline"
                :md="logOutOutline"
              ></ion-icon>
              <ion-label class="large-font">Logout</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false" v-if="!user">
            <ion-item
              router-direction="root"
              router-link="/"
              lines="none"
              detail="false"
              class="hydrated"
              :class="{ selected: $route.path === '/' }"
            >
              <ion-label class="large-font">Home</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false" v-if="!user">
            <ion-item
              router-direction="root"
              router-link="/login"
              lines="none"
              detail="false"
              class="hydrated"
              :class="{ selected: $route.path === '/login' }"
            >
              <ion-icon
                slot="start"
                :ios="logInOutline"
                :md="logInOutline"
              ></ion-icon>
              <ion-label class="large-font">Login to Your Account</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false" v-if="!user">
            <ion-item
              color="primary"
              router-direction="root"
              router-link="/register"
              lines="none"
              detail="false"
              class="hydrated"
              :class="{ selected: $route.path === '/register' }"
            >
              <ion-icon
                slot="start"
                :ios="logInOutline"
                :md="logInOutline"
              ></ion-icon>
              <ion-label class="large-font">Register A New Account</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle auto-hide="false" v-if="!user">
            <ion-item
              href="https://blog.umpirecheatsheet.com/"
              lines="none"
              detail="false"
              class="hydrated"
            >
              <ion-icon
                slot="start"
                :ios="archiveOutline"
                :md="archiveOutline"
              ></ion-icon>
              <ion-label class="large-font">Our Blog</ion-label>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { useHead } from "unhead";
import {
  IonApp,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRouterOutlet,
  IonSplitPane,
  IonSelect,
  IonSelectOption,
  IonCardTitle,
  IonCardHeader,
} from "@ionic/vue";
import { defineComponent } from "vue";
import {
  addCircleOutline,
  archiveOutline,
  archiveSharp,
  bookmarkOutline,
  bookmarkSharp,
  heartOutline,
  heartSharp,
  mailOutline,
  mailSharp,
  paperPlaneOutline,
  paperPlaneSharp,
  trashOutline,
  trashSharp,
  warningOutline,
  warningSharp,
  logInOutline,
  logOutOutline,
  refreshOutline,
} from "ionicons/icons";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonRouterOutlet,
    IonSplitPane,
    IonSelect,
    IonSelectOption,
    IonCardTitle,
    IonCardHeader,
  },

  data() {
    return {
      appPages: [
        {
          title: "Inbox",
          url: "/folder/Inbox",
          iosIcon: mailOutline,
          mdIcon: mailSharp,
        },
        {
          title: "Outbox",
          url: "/folder/Outbox",
          iosIcon: paperPlaneOutline,
          mdIcon: paperPlaneSharp,
        },
        {
          title: "Favorites",
          url: "/folder/Favorites",
          iosIcon: heartOutline,
          mdIcon: heartSharp,
        },
        {
          title: "Archived",
          url: "/folder/Archived",
          iosIcon: archiveOutline,
          mdIcon: archiveSharp,
        },
        {
          title: "Trash",
          url: "/folder/Trash",
          iosIcon: trashOutline,
          mdIcon: trashSharp,
        },
        {
          title: "Spam",
          url: "/folder/Spam",
          iosIcon: warningOutline,
          mdIcon: warningSharp,
        },
      ],
      labels: ["Family", "Friends", "Notes", "Work", "Travel", "Reminders"],
      archiveOutline,
      addCircleOutline,
      archiveSharp,
      bookmarkOutline,
      bookmarkSharp,
      heartOutline,
      heartSharp,
      mailOutline,
      mailSharp,
      paperPlaneOutline,
      paperPlaneSharp,
      trashOutline,
      trashSharp,
      warningOutline,
      warningSharp,
      logInOutline,
      logOutOutline,
      refreshOutline,
      selectedOrganization: null,
      organizations: [],
    };
  },
  head() {
    return {
      title: "Umpire Cheat Sheet - Helping Umpires Remember Rulesets",
    };
  },
  watch: {
    selectedOrganization: function (value) {
      // if(value) {
      //   this.$router.push({ path: '/organization/' + value })
      // }
    },
    $route: function (value) {
      if (value.fullPath === "/") {
        useHead({
          title: "Umpire Cheat Sheet - Helping Umpires Remember Rulesets",
        });
      }
    },
  },
  mounted: function () {
    if (this.user) {
      this.$axios.get("/organizations/" + this.user.id).then((results) => {
        this.organizations = results.data;
      });
    }

    this.selectedOrganization = this.$route.params.id;
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    ...mapActions(["logout"]),
  },
});
</script>

<style scoped>
ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}

.hydrated {
  cursor: pointer;
}
</style>
