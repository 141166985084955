import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ScriptX from "vue-scriptx";

import { IonicVue } from "@ionic/vue";
import { createHead, VueHeadMixin } from "@unhead/vue";

import axios from "axios";
import axiosSetup from "./helpers/interceptors";

import Vue3Tour from 'vue3-tour'
import 'vue3-tour/dist/vue3-tour.css'

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";
axiosSetup();
const head = createHead();
const app = createApp(App)
  .use(IonicVue)
  .use(ScriptX)
  .use(store)
  .use(router)
  .use(Vue3Tour)
  .mixin(VueHeadMixin)
  .use(head);
app.config.globalProperties.$axios = axios;
router.isReady().then(() => {
  app.mount("#app");
});
