import { createRouter, createWebHistory } from "@ionic/vue-router";
import store from "../store/index";
import { showToast, showLoading } from "@/helpers/toastLoadHelper.js";

const routes = [
  {
    path: "/",
    component: () => import("../views/HomePage.vue"),
    meta: { requiresLogin: false },
  },
  {
    path: "/contact-us",
    component: () => import("../views/ContactUs.vue"),
    meta: { requiresLogin: false },
  },
  {
    path: '/org/:slug',
    component: () => import("../views/FrontendOrgPage.vue"), //// here
  },
  {
    path: "/create-new-organization",
    component: () => import("../views/CreateOrganization.vue"),
    meta: { requiresLogin: true },
  },
  {
    path: "/organization/:id?/:tab?",
    name: 'Organization',
    component: () => import("../views/OrganizationPage.vue"),
    meta: { requiresLogin: true },
  },
  {
    path: "/blog/:slug",
    name: 'BlogPost',
    component: () => import("../views/BlogPost.vue"),
  },
  {
    path: "/register",
    component: () => import("../views/UserRegister.vue"),
    beforeEnter: async (to, from, next) => {
      if (store.state.user) {
        next({
          path: "/organization/all",
        });
      } else {
        next();
      }
    },
  },
  {
    path: "/change-password",
    component: () => import("../views/ChangePassword.vue"),
    meta: { requiresLogin: true },
  },
  {
    path: "/login",
    component: () => import("../views/UserLogin.vue"),
    beforeEnter: async (to, from, next) => {
      if (store.state.user) {
        next({
          path: "/organization/all",
        });
      } else {
        next();
      }
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (!store.state.initialize) {
    const loading = await showLoading("Please wait...");
    await store.dispatch("refreshToken");
    loading.dismiss();
    store.state.initialize = 1;
  }
  
  if (to.matched.some((record) => record.meta.requiresLogin === true)) {
    if (!store.state.user) {
      showToast("Please Login", "warning");
      next({
        path: "/login",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
